import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { SelectChangeEvent } from '@mui/material';
import useSchools from '../hooks/useSchools';
import Loader from '../reusable/Loader';
import PageHeader from '../reusable/PageHeader';
import { NAVIGATION_LINKS_DATA } from '../../constants';
import SelectDropdown from '../reusable/SelectDropdown';
import useUploadProgress from '../hooks/useUploadProgress';

import './DataQcLayout.css';

const pageDescription = 'Review uploaded district data and make sure it\'s accurate.';
const allSchoolsValue = 'All schools';

function DataQcLayout() {
  const [contextData, setContextData] = useState({ selectedSchoolFromDataQc: '' });

  const { schools: { isPending: isSchoolsDataPending, error: schoolsError, data: schools } } = useSchools();

  const { isPending: isUploadProgressPending, error: uploadProgressError, data: uploadProgress } = useUploadProgress();

  if (isUploadProgressPending) return <Loader />;
  if (uploadProgressError || !uploadProgress) return <p>Error with upload progress data!</p>;

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;

    if (value === allSchoolsValue) setContextData({ selectedSchoolFromDataQc: '' });
    else setContextData({ selectedSchoolFromDataQc: value });
  };

  const getDropdownItems = () => {
    if (!schools) return [];

    let items = [{ label: allSchoolsValue, value: allSchoolsValue}];
    schools.forEach((school) => items.push({ label: school.name, value: school.id }));

    return items;
  };

  return (
    <>
      <PageHeader title={NAVIGATION_LINKS_DATA.DATA_QC.label} pageDescription={pageDescription} />
      { uploadProgress.progress.length + uploadProgress.completed.length !== 0 &&
        <div className="select-school-bar">
          {isSchoolsDataPending ? <Loader /> : null}
          {schoolsError ? <p>Missing schools data!</p> : null}
          { schools &&
            <>
              <span>Show data for</span>
              <SelectDropdown
                label="School"
                selectedValue={contextData.selectedSchoolFromDataQc || allSchoolsValue}
                handleChange={handleChange}
                items={getDropdownItems()}
                isClearable={false}
              />
            </>
          }
        </div>
      }

      <Outlet context={{
        selectedSchoolFromDataQc: contextData.selectedSchoolFromDataQc,
        uploadProgress
      }}
      />
    </>
  );
}

export default DataQcLayout;