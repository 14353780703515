import CustomModal from '../reusable/CustomModal';
import CustomButton from '../reusable/CustomButton';
import SuccessIcon from '../assets/data-upload-success.svg';
import XMarkIcon from '../assets/data-upload-x-mark.svg';
import WarningIcon from '../assets/data-upload-warning-amber.svg';
import { FileUploadResult, UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import CustomDivider from '../reusable/CustomDivider';
import FileTypesValues from '../../interfaces/FileType';
import './UploadResultsModal.css';

type FileTypes = 'STUDENT_ROSTER' | 'COURSE_CATALOG' | 'STUDENT_DEMOGRAPHICS' | 'TRANSCRIPT' | 'STUDENT_TEST_SCORES' | 'CTE_PATHWAY_CATALOG';

const headerMapping: Record<FileTypes, Record<string, string>> = {
  CTE_PATHWAY_CATALOG: {
    academic_periods: 'Academic Years',
    schools: 'Schools',
  },
  COURSE_CATALOG: {
    academic_periods: 'Academic Years',
    schools: 'Schools',
  },
  TRANSCRIPT: {
    schools: 'Schools',
  },
  STUDENT_ROSTER: {
    schools: 'Schools',
  },
  STUDENT_DEMOGRAPHICS: {
    demographic_types: 'Demographic type',
    schools: 'Schools',
  },
  STUDENT_TEST_SCORES: {
    test_types: 'Test type',
    schools: 'Schools',
  }
};

interface MockErrors extends FileUploadResult {
  missingColumns: string[];
}

interface UploadResultsModalProps {
  fileType: FileTypesValues;
  handleCloseModal: () => void;
  handleResubmit: () => void;
  results: FileUploadResult[];
  statistics: string[];
  isViewOnly: boolean;
  fileMetadata: UploadedFileData['user_provided_file_metadata'];
};

function UploadResultsModal({fileType, handleCloseModal, handleResubmit, results, statistics, fileMetadata, isViewOnly}: UploadResultsModalProps) {

  const renderStatusIcon = (status: string) => {
    switch (status) {
    case 'success':
      return SuccessIcon;
    case 'failure':
      return XMarkIcon;
    default:
      return WarningIcon;
    }
  };

  return (
    <CustomModal 
      title="Upload results" 
      isOpen={true} 
      handleClose={handleCloseModal} 
      icon={false}
    >
      <div className="upload-results-container">
        <p className="upload-results-title">File overview</p>
        <ul>
          {statistics.map((statistic, index) => <li key={index}>{statistic}</li>)}
        </ul>
        {results.length > 0 && (
          <>
            <p className="upload-results-title">Data matching</p>
            {results.map((result, index) => (
              <div key={index} className={`result-container ${result.status}`}>
                <div className="result-status">
                  <img src={renderStatusIcon(result.status)} alt="data matching status icon" />
                  <p>{result.description}</p>
                </div>
                {result.hasOwnProperty('missingColumns') && (
                  <ul className="missing-columns">
                    {(result as MockErrors).missingColumns.map((column, idx) => (
                      <li key={idx}>{column}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </>
        )}
        <div className="files-metadata-section">
          <p>Your selections</p>
          <div className="files-metadata">
            {/* sort the entries so that schools are always last in order */}
            {Object.entries(fileMetadata).sort((metaData1) => metaData1[0] === 'schools' ? 1 : -1).map(([key, value]) => {
              const header = headerMapping[fileType as keyof typeof headerMapping][key];

              return header ?
                <div key={key} className="meta-data-container">
                  <h4>{header}</h4>
                  <div className="file-metadata">
                    { value && value.map((val, index) => (
                      <p key={index}>
                        {typeof val === 'object' ? val.name : val}
                      </p>
                    ))
                    }
                  </div>
                </div>
                : 
                null;
            })}
          </div>
        </div>
        <CustomDivider size="medium" orientation="horizontal" />
        {!isViewOnly &&
          <div className="custom-modal-actions-container">
            <CustomButton variant="secondary" buttonText="Replace" buttonAction={handleResubmit} />
            <CustomButton variant="primary" buttonText="Continue" buttonAction={handleCloseModal} />
          </div>
        }
      </div>
    </CustomModal>
  );
}

export default UploadResultsModal;