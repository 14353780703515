import CustomButton from '../../reusable/CustomButton';
import CustomDivider from '../../reusable/CustomDivider';
import Tag from '../../reusable/Tag';
import PrintIcon from '../../assets/print.svg';
import { TranscriptDetails, TranscripsCategoriesKeys, Transcript } from '../../../interfaces/TranscriptsInterface';
import useSchools from '../../hooks/useSchools';
import { useNavigate } from 'react-router-dom';
import GoBackIcon from '../../assets/transcript-selection-arrow-left.svg';
import { useTranscriptSelectionOutletContext } from '../../hooks/useTranscriptSelectionOutletContext';
import { Checkbox, FormControlLabel } from '@mui/material';
import { selectTranscript } from '../utilityFunctions';
import useUserDetails from '../../hooks/useUserDetails';
import useErrorModal from '../../hooks/useErrorModal';
import { USER_ROLES, TRANSCRIPTS_CATEGORIES_LABELS } from '../../../constants';
import { trimTo2Decimals } from '../../../utils';

import './TranscriptDetailsHeader.css';

interface TranscriptDetailsHeaderProps {
  data: TranscriptDetails;
  category: TranscripsCategoriesKeys;
  transcript: Transcript;
}

const summaryData = {
  'gpa': 'GPA',
  'credits': 'Credits',
  'race': 'Race/Ethnicity',
  'gender': 'Gender',
  'student_with_disabilities': 'Disability Status',
  'english_learner': 'English Language Learner',
  'low_income': 'Low Income'
};

function TranscriptDetailsHeader ({data, category, transcript}: TranscriptDetailsHeaderProps) {
  const { selectedSchoolFromSideNav, schools } = useSchools();
  const { data: userDetails } = useUserDetails();
  const { handleOpen: handleOpenErrorModal } = useErrorModal();
  const navigate = useNavigate();

  const userRole = userDetails?.role;
  const isRestrictedView = userRole === USER_ROLES.XQ_USER || userRole === USER_ROLES.ACCOUNT_MANAGER;

  const {selectedTranscriptsIdsByCategory, setSelectedTranscriptsIdsByCategory, is_finalized} = useTranscriptSelectionOutletContext();

  const isSelected = selectedTranscriptsIdsByCategory[category].includes(transcript.id);

  const handleSelectTranscript = () => selectTranscript(userDetails, category, transcript.id, selectedTranscriptsIdsByCategory, isSelected, selectedSchoolFromSideNav, handleOpenErrorModal, setSelectedTranscriptsIdsByCategory);
  const selectedSchoolName = schools.data?.find(school => school.id === selectedSchoolFromSideNav)?.name || '';

  return (
    <div className="transcript-details-header-content">
      <CustomButton
        variant="text"
        buttonText="Back"
        icon={<img src={GoBackIcon} alt="go back"></img>}
        iconPosition="start"
        buttonAction={() => navigate('/transcripts')}
      />
      <div className="transcript-details-header">
        <h2>Transcript - {transcript.id}</h2>
        <Tag type="info" text={TRANSCRIPTS_CATEGORIES_LABELS[category]} size="medium" className="upload-progress-info" />
        <div className="buttons-container">
          {!is_finalized && !isRestrictedView ?
            <FormControlLabel
              label="Select this transcript"
              control={
                <Checkbox
                  checked={isSelected}
                  onChange={handleSelectTranscript}
                />
              }/>
            :
            null
          }
          <CustomButton
            variant="text"
            buttonText="Print All"
            icon={<img src={PrintIcon} alt="download"></img>}
            iconPosition="start"
            buttonAction={() => {}}
          />
        </div>
      </div>
      <CustomDivider orientation="horizontal" size="medium"/>
      <div className="transcript-details-summary">
        <div className="category">
          <p>School</p><span>{selectedSchoolName}</span>
        </div>
        {Object.entries(summaryData).map(([key, value]) => (
          <div className="category" key={key}>
            <p>{value}</p>
            <span className="category-value">
              {value === summaryData.gpa ? 
                trimTo2Decimals(data.gpa) 
                : 
                data[key as keyof typeof summaryData].toString().toLowerCase()
              }
            </span>
          </div>
        ))}
      </div>
      <CustomDivider orientation="horizontal" size="medium"/>
    </div>
  );
};

export default TranscriptDetailsHeader;