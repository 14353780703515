import OverviewDistrictCard from './OverviewDistrictCard';
import { UserDetails } from '../../interfaces/UserDetailsInterface';
import { fetchOverviewTranscriptsData } from './overviewPageConfig';
import useErrorModal from '../hooks/useErrorModal';
import useFetchData from '../hooks/useFetchData';
import Loader from '../reusable/Loader';
import useSchools from '../hooks/useSchools';
import OverviewStepCard from './OverviewStepCard';
import DataUploadIcon from '../assets/nav-data-upload.svg';
import DataQcIcon from '../assets/nav-data-qc.svg';
import useUploadProgress from '../hooks/useUploadProgress';

interface OverviewContentProps {
  userDetails: UserDetails;
}
function DistrictDataLeadView({ userDetails }: OverviewContentProps) {

  const { isPending, error, data } = useUploadProgress();

  if (isPending) return <Loader />;
  if (error || !data) return <p>Error occured while fetching upload progress data!</p>;

  const { pending, progress, completed } = data;

  return (
    <>
      <div className="overview-step-cards">
        <OverviewStepCard 
          cardIcon={DataUploadIcon} 
          stepNumber={1} 
          cardTitle="Upload Data" 
          cardDescription="Upload files that contain data, such as the student roster, course catalog, and student demographics, for each school." 
          buttonText="Data Upload"
          isActive={pending.length + progress.length !== 0}
          redirectTo="/data-upload"
        />
        <OverviewStepCard
          cardIcon={DataQcIcon}
          stepNumber={2}
          cardTitle="Data QC"
          cardDescription="After uploading your data, take a moment to review it and make sure it accurately represents your district."
          buttonText="Check data quality"
          isActive={pending.length + progress.length === 0}
          redirectTo="/data-qc"
        />
      </div>
      <OverviewDistrictCard 
        districtName={userDetails.district.name}
        notStartedCategories={pending}
        inProgressCategories={progress}
        completedCategories={completed}
      />
    </>
  );
}

function FacilitatorView({ userDetails }: OverviewContentProps) {
  const { schools: { isPending: isSchoolsDataPending, error: schoolsError, data: schoolsData }, updateSelectedSchool} = useSchools();

  const { handleOpen } = useErrorModal();

  const { isPending: isTranscriptsDataPending, error: transcriptsError, response: transcriptsData } = useFetchData({ 
    fetchData: (eoaId: string, access_token: string) => fetchOverviewTranscriptsData(eoaId, access_token, handleOpen, updateSelectedSchool, schoolsData), 
    errorMessage: 'Error with overview data!',
    options: {
      dependencies: [Boolean(schoolsData)]
    }
  });

  if (isTranscriptsDataPending || isSchoolsDataPending) return <Loader />;
  if (schoolsError) return <p>Missing schools data!</p>;
  if (transcriptsError || !transcriptsData) return <p>Error occured while fetching transcript selection progress data!</p>;

  const { pending, progress, completed } = transcriptsData;

  return (
    <OverviewDistrictCard 
      districtName={userDetails.district.name}
      notStartedCategories={pending}
      inProgressCategories={progress}
      completedCategories={completed}
    />
  );
}

export {
  DistrictDataLeadView,
  FacilitatorView
};