import {  Checkbox, Chip, FormControl, InputLabel, ListItemText, MenuItem, Select, SxProps } from '@mui/material';
import './MultiSelectDropdown.css';
import { useState } from 'react';
import { ReactComponent as CustomDeleteIcon } from '../assets/chip-delete-icon.svg';

interface MultiSelectDropdownProps {
  items: any;
  parentCallback: (value: string[], value2: string) => void;
  title: string;
  itemName: string;
  classes?: string[];
  sx?: SxProps;
}

function MultiSelectDropdown({ items, parentCallback, title, itemName, classes, sx }: MultiSelectDropdownProps) {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);

  const isAllSelected = items.length > 0 && selectedOptions.length === items.length;

  const classNames = classes ? classes.join(' ') : '';

  const handleChange = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value.length && value[value.length - 1].includes('All')) {

      parentCallback(selectedOptions.length === items.length ? [] : items, title.toLowerCase());
      setSelectedOptions(selectedOptions.length === items.length ? [] : items);
      return;
    }
    parentCallback(value, title.toLowerCase());
    setSelectedOptions(value);
  };

  const handleDelete = (value: string) => {
    const filteredOptions = selectedOptions.filter(selectedOption => selectedOption !== value);
    setSelectedOptions(filteredOptions);
    parentCallback(filteredOptions, title.toLowerCase());
  };

  return (
    <div className={`multiselect-dropdown ${classNames}`}>
      <div className="dropdown-title">{title}</div>
      <FormControl 
        // sx={{ 
        //   width: '60%',
        //   minWidth: '200px',
        //   maxWidth: '300px'
        // }}
        sx={sx}
        data-testid="form-control">
        <InputLabel id="select-label">Select</InputLabel>
        <Select
          labelId="mutiple-select-label"
          label="Select"
          multiple
          value={selectedOptions}
          onChange={handleChange}
          renderValue={(selected) => 
            selected.map(selectedValue => {
              return <Chip 
                key={selectedValue} 
                label={selectedValue} 
                deleteIcon={<CustomDeleteIcon data-testid={`chip-delete-icon-${selectedValue}`}/>}
                onDelete={() => handleDelete(selectedValue)}
                onMouseDown={(event) => event.stopPropagation()}
                clickable
              />;
            })
          }
        >
          <MenuItem
            value="All"
          >
            <Checkbox
              checked={isAllSelected}
              indeterminate={
                selectedOptions.length > 0 && selectedOptions.length < items.length
              }
            />
            <ListItemText
              primary={`All ${itemName}s`}
            />
          </MenuItem>
          {items.length && items.map((option: string) => (
            <MenuItem key={option} value={option}>
              <Checkbox checked={selectedOptions.indexOf(option) > -1} />
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}

export default MultiSelectDropdown;
