import { UPLOAD_SECTIONS_IDS, fileTypes, NAVIGATION_LINKS_DATA, TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS, TRANSCRIPTS_CATEGORIES, DATA_CATEGORY_LABELS } from '../../constants';
import { CategoryData } from './OverviewDistrictCard';
import { calculateFlatDataUploadProgress, calculateNestedDataUploadProgress } from '../data upload/utilityFunctions';
import { getUploadRequirementsData } from '../../services/uploadRequirementsService';
import { AxiosError } from 'axios';
import { getAllTrascriptSelectionSummaries } from '../../services/transcriptsService';
import Schools from '../../interfaces/SchoolsInterface';

interface RequestData {
  categoryName: CategoryData['categoryName'];
  errorMessage: string;
  anchorLink: CategoryData['anchorLink'];
  calculateUploadProgress: typeof calculateFlatDataUploadProgress | typeof calculateNestedDataUploadProgress;
  description: CategoryData['description'];
}

const uploadCategoryRequestsOrder: RequestData[] = [
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_ROSTER],
    errorMessage: 'Error occured while fetching student roster data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_ROSTER]}`,
    calculateUploadProgress: calculateFlatDataUploadProgress,
    description: '0'
  },
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.COURSE_CATALOG],
    errorMessage: 'Error occured while fetching course catalog data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.COURSE_CATALOG]}`,
    calculateUploadProgress: calculateNestedDataUploadProgress,
    description: '0'
  },
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_DEMOGRAPHICS],
    errorMessage: 'Error occured while fetching student demographics data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_DEMOGRAPHICS]}`,
    calculateUploadProgress: calculateNestedDataUploadProgress,
    description: '0'
  },
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_TRANSCRIPTS],
    errorMessage: 'Error occured while fetching student transcripts data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_TRANSCRIPTS]}`,
    calculateUploadProgress: calculateFlatDataUploadProgress,
    description: '0'
  },
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.TEST_SCORES],
    errorMessage: 'Error occured while fetching test scores data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.TEST_SCORES]}`,
    calculateUploadProgress: calculateNestedDataUploadProgress,
    description: '0'
  },
  {
    categoryName: DATA_CATEGORY_LABELS[fileTypes.CTE_PATHWAYS],
    errorMessage: 'Error occured while fetching CTE pathways data!',
    anchorLink: `/data-upload#${UPLOAD_SECTIONS_IDS[fileTypes.CTE_PATHWAYS]}`,
    calculateUploadProgress: calculateNestedDataUploadProgress,
    description: '0'
  }
];

const fetchOverviewCategoriesData = async (eoaId: string, access_token: string, handleError: (errorMessage: string | string[], error: Error | AxiosError) => void) => {
  let result = await getUploadRequirementsData(eoaId, access_token);

  const categoriesByUploadStatus: { [key: string]: CategoryData[] } = {
    pending: [],
    progress: [],
    completed: []
  };
  const errorMessages: string[] = [];

  uploadCategoryRequestsOrder.forEach((request, index) => {
    let responseData = result[index];
    if (responseData.status === 'fulfilled') {
      let result = request.calculateUploadProgress(responseData.value.data);

      categoriesByUploadStatus[result.state].push({ 
        categoryName: request.categoryName, 
        anchorLink: request.anchorLink,
        description: result.description,
        uploadPercent: result.uploadPercent
      });
    } else {
      errorMessages.push(request.errorMessage);
    }
  });

  if (errorMessages.length > 0) handleError(errorMessages, new Error('Upload requirement fetch error'));

  return categoriesByUploadStatus;
};

const fetchOverviewTranscriptsData = async (eoaId: string, access_token: string, handleError: (errorMessage: string | string[], error: Error | AxiosError) => void, updateSelectedSchool: (schoolId: string) => void, schools?: Schools[]) => {
  if (!schools) return null;
  
  let schoolIds = schools.map(school => school.id);
  let result = await getAllTrascriptSelectionSummaries(eoaId, access_token, schoolIds);

  const transcriptSelectionProgress: { [key: string]: CategoryData[] } = {
    pending: [],
    progress: [],
    completed: []
  };
  const errorMessages: string[] = [];
  const requiredNumberOfSelections = TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.RIGOROUS_ACADEMIC + TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.ON_THE_CUSP + TRANSCRIPTS_CATEGORIES_SELECTION_LIMITS.STRUGGLING;

  result.forEach((request, index) => {
    let responseData = request;
    if (responseData.status === 'fulfilled') {
      let data = responseData.value.data;

      if (Object.keys(data.transcripts).length === 0) {
        errorMessages.push(`Missing "${schools[index].name}" transcripts selection summary data!`);
        return;
      }
      
      let allTranscripts = data.transcripts[TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC].concat(data.transcripts[TRANSCRIPTS_CATEGORIES.ON_THE_CUSP]).concat(data.transcripts[TRANSCRIPTS_CATEGORIES.STRUGGLING]);
      let selectedTranscriptsCount = allTranscripts.filter((transcript) => transcript.is_selected).length;

      if (selectedTranscriptsCount === 0) {
        transcriptSelectionProgress.pending.push({
          categoryName: schools[index].name,
          anchorLink: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path,
          description: '',
          onClick: () => updateSelectedSchool(schools[index].id)
        });
      } else if (data.is_finalized) {
        transcriptSelectionProgress.completed.push({
          categoryName: schools[index].name,
          anchorLink: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path,
          description: '',
          onClick: () => updateSelectedSchool(schools[index].id)
        });
      } else {
        transcriptSelectionProgress.progress.push({
          categoryName: schools[index].name,
          anchorLink: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path,
          description: `${selectedTranscriptsCount}/${requiredNumberOfSelections} students selected`,
          onClick: () => updateSelectedSchool(schools[index].id)
        });
      }
    } else {
      errorMessages.push(`Error occured while fetching "${schools[index].name}" transcripts selection summary data!`);
    }
  });

  if (errorMessages.length > 0) handleError(errorMessages, new Error('Upload requirement fetch error'));

  return transcriptSelectionProgress;
};

export {
  uploadCategoryRequestsOrder,
  fetchOverviewCategoriesData,
  fetchOverviewTranscriptsData
};