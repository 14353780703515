import { useEffect, useState } from 'react';
import { deleteConfiguredSchool, editConfiguredSchool, getConfiguredSchools } from '../../../services/eoaConfigServices';
import useFetchData from '../../hooks/useFetchData';
import useSchools from '../../hooks/useSchools';
import CustomButton from '../../reusable/CustomButton';
import Loader from '../../reusable/Loader';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import useUserDetails from '../../hooks/useUserDetails';
import useErrorModal from '../../hooks/useErrorModal';
import { AxiosError } from 'axios';
import {compare, Operation} from 'fast-json-patch';
import { ConfiguredEOASchool } from '../../../interfaces/EOAInterface';

import './ConfiguredSchools.css';
import SchoolDataFields from './SchoolDataFields';

function ConfiguredSchools() {

  const { selectedSchoolFromSideNav } = useSchools();
  const { handleOpen } = useErrorModal();
  const { data: userDetails } = useUserDetails();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId: string, access_token: string) => getConfiguredSchools(eoaId, access_token, selectedSchoolFromSideNav),
    errorMessage: 'Error occurred while fetching transcript selection demographics data!',
    options: {
      dependencies: [selectedSchoolFromSideNav]
    }
  });

  const [currentSchool, setCurrentSchool] = useState({id: '', name: '', school_identifier: '', school_type: ''});
  const [schools, setSchools] = useState<ConfiguredEOASchool[]>([]);

  useEffect(() => {
    if (response?.data) {
      setSchools(response.data);
    }
  }, [response]);

  const handleSelectSchoolForEdit = (id: string) => {
    const selectedSchool = schools.find(school => school.id === id);
    if (selectedSchool) {
      setCurrentSchool(selectedSchool);
    }
  };

  const handleSaveEditedSchool = async () => {

    let patchOperations: Operation[] = [];
    
    const currentSchoolData = schools.find(school => school.id === currentSchool.id);
    if (currentSchoolData) patchOperations = compare(currentSchoolData, currentSchool);
    if (!patchOperations.length) return;
  
    if (userDetails && patchOperations.length) {
      try {
        await editConfiguredSchool(userDetails?.eoaId, userDetails?.access_token, currentSchool.id, patchOperations);
        
        setSchools((prev) => prev.map((school) => school.id === currentSchool.id ? { ...school, ...currentSchool } : school));
        setCurrentSchool({ id: '', name: '', school_identifier: '', school_type: '' });
      } catch (error) {
        console.log(error);
        handleOpen('Error occured while editing a configured school!', error as AxiosError);
      }
    }
  };

  const deleteSchool = async (id: string) => {
    if (userDetails) {
      try {
        await deleteConfiguredSchool(userDetails?.eoaId, userDetails?.access_token, id);

        setSchools((prev) => prev.filter((school) => school.id !== id));
      } catch (error) {
        console.log(error);
        handleOpen('Error occured while deleting a configured school!', error as AxiosError);
      }
    }
  };

  if (isPending) return <Loader />;
  if (error) return <p>Missing configured schools data!</p>;
      
  return (
    <>
      <h3>Configured schools</h3>
      {schools.map(school => 
        currentSchool.id === school.id ?
          (
            <div key={school.id} className="school-config-data-fields">
              <SchoolDataFields 
                currentSchool={currentSchool} 
                setCurrentSchool={setCurrentSchool}
              />
              <CustomButton
                variant="primary"
                size="medium"
                buttonText="Save"
                buttonAction={handleSaveEditedSchool}
                disabled={!currentSchool.name || !currentSchool.school_type} />
              <CustomButton
                variant="secondary"
                size="medium"
                buttonText="Cancel"
                buttonAction={() => setCurrentSchool({ id: '', name: '', school_identifier: '', school_type: '' })}
                disabled={!currentSchool.name || !currentSchool.school_type} />
            </div>
          ) :
          <div key={school.id} className="school-item">
            <div >
              <p>{school.name}</p>
              <p>ID: {school.school_identifier}</p>
              <p>Type: {school.school_type}</p>
            </div>
            <div className="school-item-actions">
              <CustomButton 
                variant="clear" size="medium" 
                icon={<EditOutlinedIcon />} 
                buttonAction={() => handleSelectSchoolForEdit(school.id)} 
                iconPosition="start"
              />
              <CustomButton 
                variant="clear" 
                size="medium" 
                icon={<DeleteOutlineOutlinedIcon/>} 
                buttonAction={() => deleteSchool(school.id)} 
                iconPosition="start"
              />
            </div>
          </div>
      )}
    </>
  );
}

export default ConfiguredSchools;