// import FileIconGreen from '../assets/file-icon-green.svg';
import CloseIcon from '../assets/close-icon.svg';
import './WhatToUpload.css';  

interface WhatToUploadProps {
  cardTitle: string;
  fileTypeLabel: string;
  handleCloseSidebar: () => void;
  children: React.ReactNode;
}

const WhatToUpload = ({ cardTitle, fileTypeLabel, handleCloseSidebar, children }: WhatToUploadProps) => {

  return (
    <>
      <div className="helper-section">
        <div className="helper-section-title"> 
          <h2>{cardTitle}</h2>
          <img className="pointer" src={CloseIcon} alt="close sidebar icon" onClick={handleCloseSidebar}/>
        </div>
        <p>View upload progress for {fileTypeLabel} data. If there are errors, click “Replace file” to upload a corrected file.</p>
        {/* change the href with real link */}
        <p>Questions? Contact <a href="https://www.example.com" className="link-color">support</a>.</p>
        {/* <div className="example-file-container">
          <img alt="file icon" src={FileIconGreen}/>
          <div className="file-info-container">
            <a data-testid="example file link" href={`/example files/${sampleFileName}`} download={sampleFileName}>Download an example file</a>
          </div>
          <div className="spacer"></div>
        </div> */}
      </div>
      {children}
    </>
  );
};

export default WhatToUpload;