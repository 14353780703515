import { useOutletContext } from 'react-router-dom';
import { CategoryData } from '../overview/OverviewDistrictCard';

interface OutletContextValues {
  selectedSchoolFromDataQc: string;
  uploadProgress: {
    [key: string]: CategoryData[]
  }
}

export function useDataQcOutletContext() {
  return useOutletContext<OutletContextValues>();
}

export type {
  OutletContextValues
};