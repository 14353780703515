import { Outlet } from 'react-router-dom';
import { useState, useEffect } from 'react';
import useFetchData from '../hooks/useFetchData';
import { getTranscriptSelectionSummary } from '../../services/transcriptsService';
import useSchools from '../hooks/useSchools';
import Loader from '../reusable/Loader';
import { TRANSCRIPTS_CATEGORIES } from '../../constants';
import { SelectedTranscriptsIdsByCategory, TranscripsCategoriesKeys } from '../../interfaces/TranscriptsInterface';

function TranscriptSelectionLayout() {

  const { selectedSchoolFromSideNav } = useSchools();

  const [selectedTranscriptsIdsByCategory, setSelectedTranscriptsIdsByCategory] = useState<SelectedTranscriptsIdsByCategory>({
    [TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC]: [],
    [TRANSCRIPTS_CATEGORIES.ON_THE_CUSP]: [],
    [TRANSCRIPTS_CATEGORIES.STRUGGLING]: [],
  });
  const [selectedTranscriptId, setSelectedTranscriptId] = useState<number>();

  const { isPending, error, response } = useFetchData({
    fetchData: (eoaId: string, access_token: string) => getTranscriptSelectionSummary(eoaId, access_token, selectedSchoolFromSideNav),
    errorMessage: 'Error occurred while fetching transcript selection summary data!',
    options: {
      dependencies: [selectedSchoolFromSideNav]
    }
  });

  useEffect(() => {
    if (response) {
      (Object.keys(TRANSCRIPTS_CATEGORIES) as TranscripsCategoriesKeys[]).forEach((category) => {
        let transcripts = response.data.transcripts[category];
        let selectedTranscripts: string[] = [];

        if (!transcripts) return;
        
        transcripts.forEach((transcript) => {
          if (transcript.is_selected) selectedTranscripts.push(transcript.id);
        });

        selectedTranscriptsIdsByCategory[category] = selectedTranscripts;
      });

      setSelectedTranscriptsIdsByCategory({...selectedTranscriptsIdsByCategory});
    }
  }, [response]);

  if (isPending) return <Loader/>;
  if (error || !response) return <p className="center-text">Error occurred while fetching transcript selection summary data!</p>;

  const contextValues = {
    selectedTranscriptsIdsByCategory,
    setSelectedTranscriptsIdsByCategory,
    selectedTranscriptId,
    setSelectedTranscriptId,
    transcripts: response.data.transcripts,
    is_finalized: response.data.is_finalized,
    categoriesOrder: [TRANSCRIPTS_CATEGORIES.RIGOROUS_ACADEMIC, TRANSCRIPTS_CATEGORIES.ON_THE_CUSP, TRANSCRIPTS_CATEGORIES.STRUGGLING]
  };

  return (
    <Outlet context={contextValues} />
  );
}

export default TranscriptSelectionLayout;