import { ReactElement } from 'react';
import CustomTooltip from '../reusable/CustomTooltip';
import { useDataQcOutletContext } from '../hooks/useDataQcOutletContext';
import InsufficientData from './InsufficientData';

import './CardContainer.css';

interface CardContainerProps {
  title: string;
  tooltipTitle?: string | ReactElement<any, any> | undefined;
  centerTitle?: boolean;
  children: React.ReactNode;
  classes?: Array<string>;
  button?: React.ReactNode;
  customHeader?: React.ReactNode;
  noTitle?: boolean;
  icon?: string | null;
  categoryDataLabel?: string;
  categoryId?: string;
}

function CardContainer({title, tooltipTitle, children, classes, button, customHeader, centerTitle = false, noTitle = false, icon, categoryDataLabel, categoryId}: CardContainerProps) {
  const { uploadProgress } = useDataQcOutletContext();

  const classNames = classes ? classes.join(' ') : '';
  const isUploadCompleted = uploadProgress.completed.find((category) => category.categoryName === categoryDataLabel);
  const isUploadedEnoughData = uploadProgress.progress.find((category) => category.categoryName === categoryDataLabel && (category.uploadPercent && category.uploadPercent >= 50));
  const hasSufficientData = isUploadCompleted || isUploadedEnoughData || !categoryDataLabel;

  return (
    <div className={`card-container ${classNames}`} data-testid="card-container">
      {noTitle ? 
        null
        :
        <div className={`card-container-header ${centerTitle ? 'centered' : ''}`} data-testid="card-container-header">
          {customHeader ?
            customHeader
            :
            <>
              <div className="card-container-title">
                <p>{title}</p>
                {icon ? 
                  <CustomTooltip 
                    title={tooltipTitle}
                    placement="top"
                    arrow={true}>
                    <img src={icon} alt="info icon"/>
                  </CustomTooltip>
                  : 
                  null
                }
              </div>
            </>
          }
          {button ? button : null}
        </div>
      }
      { hasSufficientData ?
        <div className="card-container-content" data-testid="card-container-content">
          {children}
        </div>
        :
        <InsufficientData category={title} categoryId={categoryId || ''} />
      }
    </div>
  );
}

export default CardContainer;