import { FlatUploadRequirementsData, NestedUploadRequirementsData } from '../../interfaces/UploadRequirementsInterface';
import { PossibleUploadStates } from '../../interfaces/UploadRequirementsInterface';

type UploadProgressState = 'pending' | 'progress' | 'completed';
type ResultObjectArguments = {
  uploadedSchoolsCount: number,
  totalSchoolsCount: number,
  hasStarted: boolean
};
const createResultObject = ({ uploadedSchoolsCount, totalSchoolsCount, hasStarted }: ResultObjectArguments) => {
  /*
    pending - no data uploaded at all
    progress:
      - description is "x/y schools data uploaded"
        * full data for at least 1 school has been uploaded when its nested structure
        * at least 1 file was successfully processed when its flat structure
      - description is "in progress"
        * some data for a school has been uploaded but no school has full data when its nested structure
        * file have been uploaded but not successfully processed when its flat structure
    completed: all required data is uploaded
  */

  let state: UploadProgressState = 'pending';
  let description = `${uploadedSchoolsCount}/${totalSchoolsCount} Schools Data Uploaded`;

  if (uploadedSchoolsCount === 0) {
    description = hasStarted ? 'In Progress' : 'Not Started';
  }
  if ((uploadedSchoolsCount > 0 && uploadedSchoolsCount !== totalSchoolsCount) || hasStarted) state = 'progress';
  if (uploadedSchoolsCount === totalSchoolsCount) state = 'completed';

  return { state, description, uploadPercent: (uploadedSchoolsCount / totalSchoolsCount) * 100 };
};

export const calculateFlatDataUploadProgress = (flatData: FlatUploadRequirementsData) => {
  let uploadedSchoolsCount = 0;
  let schoolNames = Object.keys(flatData.schools);
  let hasStarted = false;

  schoolNames.forEach(schoolName => {
    if (flatData.schools[schoolName] !== PossibleUploadStates.NOT_YET_UPLOADED) hasStarted = true;
    if (flatData.schools[schoolName] === PossibleUploadStates.UPLOADED) uploadedSchoolsCount++;
  });

  return createResultObject({ uploadedSchoolsCount, totalSchoolsCount: schoolNames.length, hasStarted });
};

export const calculateNestedDataUploadProgress = (nestedData: NestedUploadRequirementsData) => {
  let uploadedSchoolsCount = 0;
  let schoolNames = Object.keys(nestedData.schools);
  let hasStarted = false;

  schoolNames.forEach(schoolName => {
    let uploadStatuses = Object.values(nestedData.schools[schoolName]);
    let hasProgress = uploadStatuses.some(uploadStatus => uploadStatus !== PossibleUploadStates.NOT_YET_UPLOADED);
    if (hasProgress) hasStarted = true;
    let isAllDataUploaded = uploadStatuses.every(uploadStatus => uploadStatus === PossibleUploadStates.UPLOADED);
    if (isAllDataUploaded) uploadedSchoolsCount++;
  });

  return createResultObject({ uploadedSchoolsCount, totalSchoolsCount: schoolNames.length, hasStarted });
};