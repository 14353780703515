import { CtePathwaysSummary } from '../../../interfaces/DataQcInterface';
import { getCtePathwaysSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import DataQcCardInfoGrid from './DataQcCardInfoGrid';

interface CteCoursesContentProps {
  data: CtePathwaysSummary;
}

function CteCoursesContent({data}: CteCoursesContentProps) {

  return (
    <div className="cte-courses-content" data-testid="cte-courses-list">
      <DataQcCardInfoGrid items={[
        { label: 'CTE catalogs', value: data.cte_catalogs_count },
        { label: 'classes eligible for CTE pathways', value: data.cte_eligible_courses_count },
        { label: 'CTE pathways', value: data.cte_pathways_count },
      ]}
      />
    </div>
  );
}

const CteCoursesContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CteCoursesContent,
    fetchData: (eoaId: string, access_token: string) => getCtePathwaysSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching CTE pathways summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CteCoursesContentWithData;