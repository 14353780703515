import { useState, useMemo } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { getCtePathwaysDetails } from '../../../services/dataQcDetailsService';
import SelectDropdown from '../../reusable/SelectDropdown';
import FlowDiagram from '../flow diagram/FlowDiagram';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { withData } from '../../reusable/withData';
import { CtePathwaysDetails } from '../../../interfaces/DataQcInterface';
import CustomDivider from '../../reusable/CustomDivider';
import DataQcCardInfoGrid from './DataQcCardInfoGrid';

import './CtePathwaysSideDrawerContent.css';

interface CtePathwaysDetailsProps {
  data: CtePathwaysDetails;
}

export function CtePathwaysSideDrawerContent({data}: CtePathwaysDetailsProps) {
  const { academic_periods } = data;

  const [selectedPeriod, setSelectedPeriod] = useState<string>(academic_periods[0].period);
  const [selectedPathway, setSelectedPathway] = useState<string>('');

  const filteredData = useMemo(() => {
    return academic_periods.find((academicPeriod) => academicPeriod.period === selectedPeriod);
  }, [selectedPeriod]);

  if (!filteredData) return <p>Missing data for selected year!</p>;

  const handleSelectPeriod = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value);
  };

  const handleSelectPathway = (event: SelectChangeEvent) => {
    setSelectedPathway(event.target.value);
  };
  
  return (
    <div className="cte-side-drawer-content" data-testid="cte-pathways-content">
      <div className="drawer-section cte-details-container">
        <SelectDropdown 
          label="Year"
          selectedValue={selectedPeriod}
          handleChange={handleSelectPeriod}
          handleDelete={() => setSelectedPeriod('')}
          items={academic_periods.map((academicPeriod) => {
            return { label: academicPeriod.period, value: academicPeriod.period };
          })}
          fullWidth={false}
        />
        <CustomDivider orientation="horizontal" />
        <p className="no-margin">Overview</p>
        <DataQcCardInfoGrid items={[
          { label: 'CTE catalogs', value: filteredData.cte_catalogs_count },
          { label: 'classes eligible for CTE pathways', value: filteredData.cte_eligible_courses_count },
          { label: 'CTE pathways', value: filteredData.cte_pathways_count },
        ]}
        />
        <CustomDivider orientation="horizontal" />
      </div>
      <div className="drawer-section flow-diagram-details-container">
        <div className="pathways-selection">
          <p className="no-margin">View pathways in</p>
          <SelectDropdown 
            label="Pathway Category"
            selectedValue={selectedPathway}
            handleChange={handleSelectPathway}
            handleDelete={() => setSelectedPathway('')}
            items={filteredData.pathways.map(pathway => {
              return { label: pathway.name, value: pathway.name };
            })}
            fullWidth={false}
          />
        </div>
        <p className="no-margin">1 result</p>
        {/* waiting for structured real data to render FlowDiagram based on selected pathway*/}
        <div className="flow-diagram-container">
          <FlowDiagram />
        </div>
      </div>
    </div>
  );
}

const CtePathwaysSideDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CtePathwaysSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getCtePathwaysDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching CTE pathways details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CtePathwaysSideDrawerContentWithData;