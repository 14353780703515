import { formatNumber } from '../../../utils';
import './DataQcCardInfoGrid.css';

interface Item {
  label: string;
  value: number;
}
interface DataQcCardInfoGridProps {
  items: Item[];
  compact?: boolean;
}
function DataQcCardInfoGrid({ items, compact = false }: DataQcCardInfoGridProps) {
  return (
    <div className={`cte-details-list ${compact ? 'compact' : ''}`} data-testid="cte-details-container">
      { items.map((item, index) => (
        <p key={index}>
          <span className="item-value accent-text">{formatNumber(item.value)}</span>
          <span className="item-label">{item.label}</span>
        </p>
      ))}
    </div>
  );
}

export default DataQcCardInfoGrid;