import { useState } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { AxiosError } from 'axios';
import { configureSchool } from '../../../services/eoaConfigServices';
import useUserDetails from '../../hooks/useUserDetails';
import CustomButton from '../../reusable/CustomButton';
import useErrorModal from '../../hooks/useErrorModal';
import MultiSelectDropdown from '../../reusable/MultiSelectDropdown';

import './SchoolsConfiguration.css';
import { useNavigate } from 'react-router-dom';
import SchoolDataFields from './SchoolDataFields';

const mockTestTypes = ['SAT','ACT','IB', 'AP'];
const mockTerms = ['S1','S2'];


function SchoolsConfiguration() {
  const { data: userDetails } = useUserDetails();
  const { handleOpen } = useErrorModal();
  const navigate = useNavigate();

  const [currentSchool, setCurrentSchool] = useState({name: '', school_identifier: '', school_type: ''});
  const [selectedTestTypes, setSelectedTestTypes] = useState<string[]>([]);
  const [selectedTerms, setSelectedTerms] = useState<string[]>([]);
  const [requiredSatForGraduation, setRequiredSatForGraduation] = useState(false);
  const [requiredActForGraduation, setRequiredActForGraduation] = useState(false);



  const handleSaveSchool = async () => {

    let schoolData = {
      ...currentSchool, 
      required_sat_for_graduation: requiredSatForGraduation, 
      required_act_for_graduation: requiredActForGraduation, 
      test_types: selectedTestTypes, 
      terms: selectedTerms
    };
   
    if (userDetails) {
      try {
        await configureSchool(userDetails.eoaId, userDetails.access_token, schoolData);

        setCurrentSchool({ name: '', school_identifier: '', school_type: '' });
        setRequiredSatForGraduation(false);
        setRequiredActForGraduation(false);
        navigate('/eoa');
      } catch (error) {
        console.error(error);
        handleOpen('Error occured while configuring schools!', error as AxiosError);
      }
    }
  };

  const getSelectedTestTypes = (testTypes: string[]) => {
    setSelectedTestTypes(testTypes);
  };

  const getSelectedTerms = (terms: string[]) => {
    setSelectedTerms(terms);
  };

  const handleRequireSatForGraduation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredSatForGraduation(event.target.checked);
  };

  const handleRequireActForGraduation = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredActForGraduation(event.target.checked);
  };

  return (
    <>
      <div className="school-config-data-fields">
        <h3>Add New School</h3>
        <SchoolDataFields 
          currentSchool={currentSchool} 
          setCurrentSchool={setCurrentSchool}
        />
        <MultiSelectDropdown 
          items={mockTestTypes} 
          parentCallback={getSelectedTestTypes} 
          title="Test Types" 
          itemName="Test Type" 
          classes={['in-school-config']}
          sx={{width: '100%'}}
        />
        <MultiSelectDropdown 
          items={mockTerms} 
          parentCallback={getSelectedTerms} 
          title="Academic Terms" 
          itemName="Academic Term" 
          classes={['in-school-config']}
          sx={{width: '100%'}}
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={requiredSatForGraduation} 
              onChange={handleRequireSatForGraduation}/>
          } 
          label="Required SAT for graduation" 
        />
        <FormControlLabel 
          control={
            <Checkbox 
              checked={requiredActForGraduation} 
              onChange={handleRequireActForGraduation}/>
          } 
          label="Required ACT for graduation" 
        />
        <CustomButton
          variant="primary"
          size="medium"
          buttonText="Add School"
          buttonAction={handleSaveSchool}
          disabled={!currentSchool.name || !currentSchool.school_type}
        />
      </div>
    </>
  );
}

export default SchoolsConfiguration;
