import { getTranscriptsSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE } from '../../../constants';
import { withData } from '../../reusable/withData';
import { TranscriptsSummary } from '../../../interfaces/DataQcInterface';
import DataQcCardContentWithTable from '../DataQcCardContentWithTable';

const columns = [
  {key: 'name', className: ''},
  {key: 'transcript_count', className: 'align-right'}
];
interface TranscriptsContentProps {
  data: TranscriptsSummary;
}

export function TranscriptsContent({data}: TranscriptsContentProps) {

  const {selectedSchoolFromDataQc} = useDataQcOutletContext();

  const {total_num_transcripts, schools} = data;

  return (
    <div data-testid="transcripts-content" className="transcripts-content">
      <div className="students-schools-count">
        <p data-testid="students-data"><span>{total_num_transcripts}</span> students</p>
        {!selectedSchoolFromDataQc && <p data-testid="schools-data"><span>{schools.length}</span> schools</p>}
      </div>
      {!selectedSchoolFromDataQc &&
        <DataQcCardContentWithTable 
          schools={schools}
          columns={columns}
          totalItems={data.schools.length} 
          pageSize={DATA_QC_TRANSCRIPTS_ITEMS_PER_PAGE}
        />
      }
    </div>
  );
}

const TranscriptsContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TranscriptsContent,
    fetchData: (eoaId: string, access_token: string) => getTranscriptsSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching transcripts summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TranscriptsContentWithData;