import { USER_ROLES, NAVIGATION_LINKS_DATA, fileTypes, DATA_CATEGORY_LABELS } from '../../constants';
import CustomButton from '../reusable/CustomButton';
import CustomDivider from '../reusable/CustomDivider';
import { useLocation } from 'react-router-dom';
import OverviewIcon from '../assets/nav-overview.svg';
import DataUploadIcon from '../assets/nav-data-upload.svg';
import DataQcIcon from '../assets/nav-data-qc.svg';
import HelpIcon from '../assets/nav-help.svg';
import TranscriptsIcon from '../assets/nav-transcript.svg';
import useUserDetails from '../hooks/useUserDetails';
import SideBarNavigationLink from './SideBarNavigationLink';
import Loader from '../reusable/Loader';
import { CategoryData } from '../overview/OverviewDistrictCard';
import useUploadProgress from '../hooks/useUploadProgress';
import { ChildLink } from './SideBarNavigationLink';

import './SideBarNavigation.css';

interface ChildLinkDataObject extends ChildLink {
  categoryName: string;
}

const generateDataQCSubLinks = (uploadProgress: { [key: string]: CategoryData [] }) => {
  if (uploadProgress.progress.length + uploadProgress.completed.length === 0) return [];
  
  let subLinks: ChildLinkDataObject[] = [
    { 
      label: 'Transcripts', 
      path: '/data-qc/transcripts', 
      categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_TRANSCRIPTS], 
      disabled: true,
      children: [], 
    },
    { 
      label: 'Matching', 
      path: '/data-qc/matching', 
      categoryName: '', 
      disabled: true,
      children: [], 
    },
    { 
      label: 'Test scores', 
      path: '/data-qc/test-scores', 
      categoryName: DATA_CATEGORY_LABELS[fileTypes.TEST_SCORES], 
      disabled: true,
      children: [], 
    },
    { 
      label: 'CTE courses', 
      path: '/data-qc/cte-courses', 
      categoryName: DATA_CATEGORY_LABELS[fileTypes.CTE_PATHWAYS], 
      disabled: true,
      children: [], 
    },
    { 
      label: 'Course catalogs', 
      path: '/data-qc/course-catalogs', 
      categoryName: DATA_CATEGORY_LABELS[fileTypes.COURSE_CATALOG], 
      disabled: true,
      children: [], 
    },
  ];

  subLinks.forEach(subLinkData => {
    const isUploadCompleted = uploadProgress.completed.find((category) => category.categoryName === subLinkData.categoryName);
    const isUploadedEnoughData = uploadProgress.progress.find((category) => category.categoryName === subLinkData.categoryName && (category.uploadPercent && category.uploadPercent >= 50));
    const hasSufficientData = isUploadCompleted || isUploadedEnoughData || !subLinkData.categoryName;

    subLinkData.disabled = !hasSufficientData;
  });

  return subLinks;
};

const getNavLinks = (uploadProgress: { [key: string]: CategoryData [] }) => {
  let dataQcSubLinks = generateDataQCSubLinks(uploadProgress);
  return [
    { 
      label: NAVIGATION_LINKS_DATA.OVERVIEW.label, 
      path: NAVIGATION_LINKS_DATA.OVERVIEW.path, 
      children: [], 
      isExpandable: false, 
      icon: OverviewIcon,
      disabled: false
    }, 
    { 
      label: NAVIGATION_LINKS_DATA.DATA_UPLOAD.label, 
      path: NAVIGATION_LINKS_DATA.DATA_UPLOAD.path, 
      children: [], 
      isExpandable: false, 
      icon: DataUploadIcon,
      disabled: false
    }, 
    {
      label: NAVIGATION_LINKS_DATA.DATA_QC.label,
      path: NAVIGATION_LINKS_DATA.DATA_QC.path,
      children: dataQcSubLinks, 
      isExpandable: dataQcSubLinks.length > 0,
      icon: DataQcIcon,
      disabled: false
    },
    { 
      label: NAVIGATION_LINKS_DATA.TRANSCRIPTS.label, 
      path: NAVIGATION_LINKS_DATA.TRANSCRIPTS.path, 
      children: [], 
      isExpandable: false, 
      icon: TranscriptsIcon,
      disabled: false
    }
  ];
};
  
const helperLinks = [
  {label: 'Help', path: '/help', children: [], isExpandable: false, icon: HelpIcon}
];

interface SideBarNavigationContentProps {
    classes: string[];
    isOpen: boolean;
    buttonAction: () => void;
    src: string;
    handleCloseSideDrawer?: () => void;
}

function SideBarNavigationContent({classes, isOpen, buttonAction, src, handleCloseSideDrawer}: SideBarNavigationContentProps) {
  const location = useLocation();

  const { isPending, error, data: userDetails } = useUserDetails();

  const { data: uploadProgress } = useUploadProgress();
  
  const classNames = classes.join(' ');
  
  const filterNavLinksByUserRole = () => {
    const userRole = userDetails?.role;

    let navLinks = getNavLinks(uploadProgress);

    switch (userRole) {
    case USER_ROLES.DISTRICT_DATA_LEAD:
      return navLinks.filter(navLink => navLink.label !== NAVIGATION_LINKS_DATA.TRANSCRIPTS.label);
    case USER_ROLES.FACILITATOR:
      return navLinks.filter(navLink => navLink.label === NAVIGATION_LINKS_DATA.TRANSCRIPTS.label || navLink.label === NAVIGATION_LINKS_DATA.OVERVIEW.label);
    case USER_ROLES.ADMIN:
      return navLinks;
    default:
      return [];
    }
  };

  return (
    <nav data-testid="sidebar" className={`sidebar ${classNames}`}>
      <ul className="sidebar-items">
        <li className="navigation-link-container arrow-back">
          <CustomButton 
            variant="clear"
            buttonAction={buttonAction}
            sx={{ padding: '0', minWidth: '0' }}
          >
            <img alt="open/close navigation menu" src={src} />
          </CustomButton>
        </li>

        <li><CustomDivider size="small" orientation="horizontal" /></li>
        { isPending ? <Loader /> : null }
        { error ? <p>Missing user details!</p> : null }
        { userDetails ?
          filterNavLinksByUserRole().map(listItem => (
            <SideBarNavigationLink 
              key={listItem.label}
              path={listItem.path}
              icon={listItem.icon}
              label={listItem.label}
              children={listItem.children}
              isExpandable={listItem.isExpandable}
              currentPath={location.pathname}
              isNavigationClosed={!isOpen}
              disabled={listItem.disabled}
              handleCloseSideDrawer={handleCloseSideDrawer}
            />
          ))
          :
          null
        }
        <li><CustomDivider size="small" orientation="horizontal" /></li>

        {helperLinks.map(listItem => (
          <SideBarNavigationLink 
            key={listItem.label}
            path={listItem.path}
            icon={listItem.icon}
            label={listItem.label}
            children={listItem.children}
            isExpandable={listItem.isExpandable}
            currentPath={location.pathname}
            isNavigationClosed={!isOpen}
            handleCloseSideDrawer={handleCloseSideDrawer}
          />
        ))}
      </ul>
    </nav>
  );
}

export default SideBarNavigationContent;