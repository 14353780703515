import { TestScoresSummary } from '../../../interfaces/DataQcInterface';
import { getTestScoresSummary } from '../../../services/dataQcSummaryService';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import CustomDivider from '../../reusable/CustomDivider';
import { withData } from '../../reusable/withData';
import DataQcCardInfoGrid from '../cte courses/DataQcCardInfoGrid';

import './TestScoresContent.css';

interface TestScoresContentProps {
  data: TestScoresSummary;
}

const labels: { [key: string]: string } = {
  sat_act_score_count: 'SAT/ACT superscores',
  sat_score_count: 'SAT superscores',
  act_score_count: 'ACT superscores',
  ap_score_count: 'AP Test scores',
  ib_score_count: 'IB Test scores'
};

function TestScoresContent({data}: TestScoresContentProps) {
  
  const testScoresSummary = data;

  const cohortItems = Object.entries(testScoresSummary.cohort).map(([key, value]) => ({
    label: labels[key], value
  }));

  const allStudentsItems = Object.entries(testScoresSummary.total).map(([key, value]) => ({
    label: labels[key], value
  }));

  return (
    <div className="test-scores-content">
      <p className="test-scores-section-title">EOA Cohort</p>
      <div data-testid="cohort-students-test-scores">
        <DataQcCardInfoGrid items={cohortItems} compact={true} />
      </div>
      <CustomDivider orientation="horizontal"/>
      <p className="test-scores-section-title">All students</p>
      <div data-testid="all-students-test-scores">
        <DataQcCardInfoGrid items={allStudentsItems} compact={true} />
      </div>
    </div>
  );
}

const TestScoresContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: TestScoresContent,
    fetchData: (eoaId: string, access_token: string) => getTestScoresSummary(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching test scores summary data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default TestScoresContentWithData;