import axios from 'axios';
import { ConfiguredEOASchool, SchoolConfigData } from '../interfaces/EOAInterface';
import { Operation } from 'fast-json-patch';

const BASE_URL = process.env.REACT_APP_BASE_URL;

const getQueryParams = (school_id: string) => {
  if  (!school_id || school_id.length === 0) return {};
  
  return {
    school_id: school_id
  };
};

const getConfiguredSchools = async (eoaId: string, access_token: string, school_id: string) => {

  const params = getQueryParams(school_id);
  
  return await axios.get<ConfiguredEOASchool[]>(`${BASE_URL}/eoa/${eoaId}/schools`, {
    headers: {
      Authorization: `Bearer ${access_token}`
    },
    params
  });
};

const configureSchool = async (eoaId: string, access_token: string, schoolData: SchoolConfigData) => {
  return await axios.post(`${BASE_URL}/eoa/${eoaId}/schools`, schoolData, 
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
};

const editConfiguredSchool = async (
  eoaId: string, access_token: string, 
  school_id: string, 
  patchOperations: Operation[]
) => {

  return await axios.patch(`${BASE_URL}/eoa/${eoaId}/schools/${school_id}`, patchOperations, 
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
};

const deleteConfiguredSchool = async (eoaId: string, access_token: string, school_id: string) => {

  return await axios.delete(`${BASE_URL}/eoa/${eoaId}/schools/${school_id}`,
    {
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    });
};

export {
  getConfiguredSchools,
  configureSchool,
  editConfiguredSchool,
  deleteConfiguredSchool
};