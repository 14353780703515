import { Checkbox, TableCell, TableRow } from '@mui/material';
import ArrowFwdIcon from '../assets/nav-arrow-fwd.svg';
import CustomButton from '../reusable/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useTranscriptSelectionOutletContext } from '../hooks/useTranscriptSelectionOutletContext';
import { selectTranscript } from './utilityFunctions';
import useUserDetails from '../hooks/useUserDetails';
import useSchools from '../hooks/useSchools';
import useErrorModal from '../hooks/useErrorModal';
import { USER_ROLES } from '../../constants';
import { Transcript, TranscripsCategoriesKeys } from '../../interfaces/TranscriptsInterface';

interface TranscriptSelectionTableRowProps {
  row: Transcript;
  rowIndex: number;
  category: TranscripsCategoriesKeys;
}

const tableCells = ['id', 'gpa', 'credits', 'race', 'gender', 'student_with_disabilities', 'english_learner', 'low_income'];

function TranscriptSelectionTableRow({ row, rowIndex, category }: TranscriptSelectionTableRowProps) {

  const { selectedTranscriptsIdsByCategory, setSelectedTranscriptsIdsByCategory, setSelectedTranscriptId, is_finalized } = useTranscriptSelectionOutletContext();
  const { selectedSchoolFromSideNav } = useSchools();
  const { handleOpen: handleOpenErrorModal } = useErrorModal();
  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;
  const isRestrictedView = userRole === USER_ROLES.XQ_USER || userRole === USER_ROLES.ACCOUNT_MANAGER;

  const navigate = useNavigate();
  const selectedTranscriptsForCurrentCategory = selectedTranscriptsIdsByCategory[category];
  const isSelected = selectedTranscriptsForCurrentCategory.includes(row.id);

  const handleSelectTranscript = () => selectTranscript(userDetails, category, row.id, selectedTranscriptsIdsByCategory, isSelected, selectedSchoolFromSideNav, handleOpenErrorModal, setSelectedTranscriptsIdsByCategory);

  const handleGoToTranscriptDetails = () => {
    setSelectedTranscriptId(`${category}-${rowIndex}`);
    navigate('/transcripts/details');
  };
  
  return (
    <TableRow>
      <TableCell>
        {!is_finalized ? <Checkbox checked={isSelected} onChange={handleSelectTranscript} disabled={isRestrictedView} /> : null}
      </TableCell> 
      {tableCells.map(value => (
        <TableCell key={value} align="left" sx={{ textTransform: 'capitalize' }}>
          {row[value as keyof TranscriptSelectionTableRowProps['row']].toString().toLowerCase()}
        </TableCell>
      ))}
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        <CustomButton
          variant="clear"
          size="medium"
          buttonAction={handleGoToTranscriptDetails}
        > 
          <img alt="go to transcript details" src={ArrowFwdIcon} />
        </CustomButton>
      </TableCell>
    </TableRow>
  );
}

export default TranscriptSelectionTableRow;