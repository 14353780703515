import { useNavigate } from 'react-router-dom';
import CustomButton from '../reusable/CustomButton';
import BarChartIcon from '../assets/bar_chart.svg';

import './InsufficientData.css';

interface InsufficientDataProps {
  category: string;
  categoryId: string;
}

function InsufficientData({ category, categoryId }: InsufficientDataProps) {
  const navigate = useNavigate();

  return (
    <div className="insufficient-data-container">
      <img src={BarChartIcon} alt="bar chart icon"></img>
      <p>More data needed</p>
      <CustomButton variant="secondary" buttonText={`Upload ${category}`} buttonAction={() => navigate(`/data-upload#${categoryId}`)} />
    </div>
  );
}

export default InsufficientData;