import { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import ArrowUpIcon from '../assets/nav-arrow-up.svg';
import ArrowDownIcon from '../assets/nav-arrow-down.svg';
import CustomButton from '../reusable/CustomButton';
import './SideBarNavigationLink.css';

export interface ChildLink {
  label: string;
  path: string;
  children: ChildLink[];
  disabled: boolean;
}
interface SideBarNavigationLinkProps {
  path: string;
  icon?: string;
  label: string;
  children: ChildLink[];
  isExpandable?: boolean;
  currentPath: string;
  isNavigationClosed: boolean;
  handleCloseSideDrawer?: () => void;
  disabled?: boolean;
}

const SideBarNavigationLink = ({
  path, 
  icon, 
  label, 
  children, 
  isExpandable, 
  currentPath,
  isNavigationClosed,
  handleCloseSideDrawer
} : SideBarNavigationLinkProps) => {

  const location = useLocation();

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    if (path === '/data-qc' && path === location.pathname) setIsExpanded(true);
  }, [location]);

  const handleExpandSubLinks = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <li data-testid="navigation-link-container" className={`navigation-link-container ${currentPath === path ? 'active' : ''}`}>
        <NavLink className="navigation-link" to={path} onClick={handleCloseSideDrawer}>
          <img className="navigation-link-icon" alt="link icon" src={icon} />
          <span className="navigation-link-text">{label}</span>
        </NavLink>
        {isExpandable && !isNavigationClosed ?
          <CustomButton 
            variant="clear"
            buttonAction={handleExpandSubLinks}
            sx={{ padding: '0', minWidth: '0' }}
          >
            <img
              id={label}
              alt={isExpanded ? 'open navigation sub menu' : 'close navigation sub menu'}
              src={isExpanded ? ArrowUpIcon : ArrowDownIcon}
              className="arrow-icon"
            />
          </CustomButton>
          :
          null
        }
      </li>

      {isExpanded && children.length > 0 && (
        <li data-testid="navigation-sub-links-container" className="navigation-sub-links-container">
          <ul className="navigation-sub-links-list">
            {children.map(({label, path, disabled}, index) => (
              <li key={index}>
                { disabled ?
                  <div className={`navigation-link-container ${disabled ? 'disabled' : ''}`}>
                    {label}
                  </div>
                  :
                  <NavLink to={path} className={`navigation-link-container ${currentPath === path ? 'active' : ''}`} onClick={handleCloseSideDrawer}>
                    {label}
                  </NavLink>
                }
              </li>
            ))}
          </ul>
        </li>
      )}
    </>
  );
};

export default SideBarNavigationLink;