import { Link, useNavigate } from 'react-router-dom';
import CardContainer from './CardContainer';
import RosterContentWithData from './RosterContent';
import DemographicsContentWithData from './demographics/DemographicsContent';
import TranscriptsContentWithData from './transcripts/TranscriptsContent';
import CteCoursesContentWithData from './cte courses/CteCoursesContent';
import MatchingContentWithData from './matching/MatchingContent';
import TestScoresContentWithData from './test scores/TestScoresContent';
import CourseCatalogsContentWithData from './course catalog/CourseCatalogsContent';
import useUserDetails from '../hooks/useUserDetails';
import { USER_ROLES, DATA_CATEGORY_LABELS, fileTypes, UPLOAD_SECTIONS_IDS } from '../../constants';
import OpenModalIcon from '../assets/data-upload-arrow-right.svg';
import InfoIcon from '../assets/info-icon.svg';
import CustomAlert from '../reusable/CustomAlert';
import ContentPlaceholderCard from './ContentPlaceholderCard';
import CustomButton from '../reusable/CustomButton';
import { useDataQcOutletContext } from '../hooks/useDataQcOutletContext';

import './DataQcSummary.css';

const cards = [
  {
    title: 'Roster: EOA cohort',
    categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_ROSTER],
    component: <RosterContentWithData />,
    path: '',
    categoryId: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_ROSTER],
    shouldRenderViewMore: false,
    infoIcon: InfoIcon,
    tooltipTitle: 'This table displays the number of students with complete data from each of the last four years.'
  },
  { 
    title: 'Test scores', 
    component: <TestScoresContentWithData />, 
    path: '/data-qc/test-scores', 
    categoryId: UPLOAD_SECTIONS_IDS[fileTypes.TEST_SCORES],
    shouldRenderViewMore: true, 
    infoIcon: InfoIcon,
    categoryName: DATA_CATEGORY_LABELS[fileTypes.TEST_SCORES],
  },
  {
    title: 'Transcripts: EOA cohort',
    categoryName: DATA_CATEGORY_LABELS[fileTypes.STUDENT_TRANSCRIPTS],
    component: <TranscriptsContentWithData />,
    path: '/data-qc/transcripts',
    categoryId: UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_TRANSCRIPTS],
    shouldRenderViewMore: true,
    infoIcon: InfoIcon,
    tooltipTitle: 'This table displays the number of student transcripts with complete data from each of the last four years.'
  },
  { 
    title: 'CTE courses', 
    categoryName: DATA_CATEGORY_LABELS[fileTypes.CTE_PATHWAYS],
    component: <CteCoursesContentWithData />, 
    path: '/data-qc/cte-courses', 
    categoryId: UPLOAD_SECTIONS_IDS[fileTypes.CTE_PATHWAYS],
    shouldRenderViewMore: true
  },
  { 
    title: 'Course catalogs', 
    categoryName: DATA_CATEGORY_LABELS[fileTypes.COURSE_CATALOG],
    component: <CourseCatalogsContentWithData />, 
    path: '/data-qc/course-catalogs', 
    categoryId: UPLOAD_SECTIONS_IDS[fileTypes.COURSE_CATALOG],
    shouldRenderViewMore: true 
  },
  { 
    title: 'Matching', 
    categoryName: '',
    component: <MatchingContentWithData />, 
    path: '/data-qc/matching', 
    categoryId: '',
    shouldRenderViewMore: true, 
    infoIcon: InfoIcon 
  }
];

function DataQcSummary() {
  const navigate = useNavigate();

  const { data: userDetails } = useUserDetails();

  const { uploadProgress } = useDataQcOutletContext();

  const handleViewMore = (path: string) => {
    if (path) navigate(path);
  };

  const renderViewMoreButton = (shouldRenderViewMore: boolean, path: string, categoryName: string, categoryId: string) => {
    const isUploadCompleted = uploadProgress.completed.find((category) => category.categoryName === categoryName);
    const isUploadedEnoughData = uploadProgress.progress.find((category) => category.categoryName === categoryName && (category.uploadPercent && category.uploadPercent >= 50));
    const hasSufficientData = isUploadCompleted || isUploadedEnoughData;

    if (shouldRenderViewMore && hasSufficientData) {
      return (
        <CustomButton
          variant="text" 
          buttonText="View more"
          buttonAction={() => handleViewMore(path)} 
          icon={<img src={OpenModalIcon} alt="open data qc summary details view"></img>}
          iconPosition="end"
          sx={{'& .MuiButton-endIcon': { marginLeft: 'var(--scaling-spacing-4)'}}}
        />
      );
    }
  };

  if (uploadProgress.progress.length + uploadProgress.completed.length === 0) return (
    <div className="data-qc-main-content main-content">
      <ContentPlaceholderCard />
    </div>
  );

  const alertType = uploadProgress.pending.length + uploadProgress.progress.length === 0 ? 'warning' : 'error';
  const alertHeader = uploadProgress.pending.length + uploadProgress.progress.length === 0 ? 'Missing information?' : 'More data needed';
  const alertBody = uploadProgress.pending.length + uploadProgress.progress.length === 0 ? 
    <p className="no-margin">
    You can upload additional data and edit files on&nbsp;
      <Link className="link" to="/data-upload">the data upload page. </Link>
    &nbsp;For more information,&nbsp;
      <Link className="link" to="mailto:yourmail@domain.com">contact your account manager</Link>.
    </p>
    :
    <p className="no-margin">You'll need data for at least ten schools to view visualizations. Once your uploads are finished processing, refresh the page and the data will appear here.</p>;

  return (
    <div className="data-qc-main-content main-content">
      {userDetails && userDetails.role !== USER_ROLES.XQ_USER ?
        <CustomAlert alertType={alertType} header={alertHeader}>
          <div className="data-qc-info-container">
            {alertBody}
          </div>
        </CustomAlert>
        :
        null
      }
      <CardContainer 
        title="Demographics"
        categoryDataLabel={DATA_CATEGORY_LABELS[fileTypes.STUDENT_DEMOGRAPHICS]}
        categoryId={UPLOAD_SECTIONS_IDS[fileTypes.STUDENT_DEMOGRAPHICS]}
      >
        <DemographicsContentWithData />
      </CardContainer>
      <div className="data-qc-cards-container">
        {cards.map(({ title, component, path, shouldRenderViewMore, infoIcon, tooltipTitle, categoryName, categoryId }, index) => (
          <CardContainer
            key={index}
            title={title}
            tooltipTitle={tooltipTitle}
            button={renderViewMoreButton(shouldRenderViewMore, path, categoryName, categoryId)}
            classes={['data-qc-summary-card']}
            icon={infoIcon}
            categoryDataLabel={categoryName}
            categoryId={categoryId}
          >
            {component}
          </CardContainer>
        ))}
      </div>
    </div>
  );
}

export default DataQcSummary;