import { Outlet, useNavigate } from 'react-router-dom';
import CustomButton from '../reusable/CustomButton';
import PageHeader from '../reusable/PageHeader';

function EOAPage() {

  const navigate = useNavigate();

  return (
    <>
      <PageHeader title="EOA" pageDescription="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."/>
      <div className="main-content">
        <div style={{display: 'flex', flexDirection: 'column', gap: 'var(--constant-spacing-16)', width: '50%', marginLeft: 'auto', marginRight: 'auto'}}>
          <CustomButton
            variant="primary"
            size="medium"
            buttonText="Configure Schools"
            buttonAction={() => navigate('/eoa/schools-configuration')}
          />
          <CustomButton
            variant="primary"
            size="medium"
            buttonText="View Configured Schools"
            buttonAction={() => navigate('/eoa/configured-schools')}
          />
        </div>
        <Outlet />
      </div>
    </>
  );
}

export default EOAPage;