import ThreeDotsLoadingIcon from '../assets/three-dots-loading-icon.svg';
import ClockIcon from '../assets/clock-icon.svg';
import CheckmarkCircleIcon from '../assets/check-icon-circle.svg';
import ArrowRight from '../assets/arrow_right.svg';
import { Link } from 'react-router-dom';
import Tag from '../reusable/Tag';
import CustomDivider from '../reusable/CustomDivider';

import './OverviewDistrictCard.css';

export interface CategoryData {
  categoryName: string;
  anchorLink: string;
  description: string;
  uploadPercent?: number;
  onClick?: () => void;
}
interface OverviewDistrictCardProps {
  districtName: string;
  notStartedCategories: CategoryData[];
  inProgressCategories: CategoryData[];
  completedCategories: CategoryData[];
}

function OverviewDistrictCard({ 
  districtName, 
  notStartedCategories, 
  inProgressCategories, 
  completedCategories 
}: OverviewDistrictCardProps) {
  return (
    <div className="overview-district-card">
      <p className="card-title">{districtName}</p>
      <div className="upload-progress-sections">
        <UploadProgressSection 
          sectionName="Not Started" 
          categories={notStartedCategories} 
          icon={<img src={ThreeDotsLoadingIcon} alt="pending work icon"></img>}
          displayTag={false}
        />
        <CustomDivider size="extra-large" orientation="vertical" className="rotating-divider" />
        <UploadProgressSection 
          sectionName="In Progress" 
          categories={inProgressCategories} 
          icon={<img src={ClockIcon} alt="clock icon"></img>}
          displayTag={true}
        />
        <CustomDivider size="extra-large" orientation="vertical" className="rotating-divider" />
        <UploadProgressSection 
          sectionName="Completed" 
          categories={completedCategories} 
          icon={<img src={CheckmarkCircleIcon} alt="checkmark icon"></img>}
          displayTag={false}
        />
      </div>
    </div>
  );
}

interface UploadProgressSectionProps {
  categories: CategoryData[];
  sectionName: string;
  icon: React.ReactElement<HTMLImageElement>;
  displayTag: boolean;
}
function UploadProgressSection({ categories, sectionName, icon, displayTag }: UploadProgressSectionProps) {
  return (
    <div className="upload-progress-section">
      <div className="section-header">
        {icon}
        <div className="header-title">
          <p className="progress-status">{sectionName}</p>
          <p className="categories-count">{categories.length} data categories</p>
        </div>
      </div>
      <ul className="categories">
        {categories.map(({ categoryName, anchorLink, description, onClick }, index) => (
          <li key={index} onClick={onClick}>
            <Link to={anchorLink} className="category">
              <div>
                <p>{categoryName}</p>
                { displayTag ?
                  <Tag type="progress" text={description} size="small" className="upload-progress-info" />
                  :
                  null
                }
              </div>
              <img src={ArrowRight} alt="go to data upload for this category"></img>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default OverviewDistrictCard;