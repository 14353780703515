import { useState, useRef, useEffect, Dispatch, SetStateAction } from 'react';
import { useDropzone } from 'react-dropzone';
import UploadIcon from '../assets/data-upload-upload-icon.svg';
import type { FileMetadata, UploadedFileData } from '../../interfaces/UploadedFileDataInterface';
import { USER_ROLES } from '../../constants';
import type FileTypesValues from '../../interfaces/FileType';
import UploadPrompt from '../data upload/upload prompts/UploadPrompt';
import { useLocation } from 'react-router-dom';
import useUserDetails from '../hooks/useUserDetails';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Tag from './Tag';
import ArrowRight from '../assets/data-upload-arrow-right.svg';
import ArrowDown from '../assets/data-upload-arrow-down.svg';
import CustomDivider from './CustomDivider';
import UploadCardContext from '../../context/UploadCardContext';
import CustomButton from './CustomButton';
import DownloadIcon from '../assets/download-icon.svg';
import UploadedFilesList from '../data upload/UploadedFilesList';

import './UploadCard.css';

export type AcceptFileTypes = {
  [key: string]: string[];
};

interface UploadCardProps {
  title: string;
  helperText: string[];
  uploadedFilesData: UploadedFileData[];
  fileType: FileTypesValues;
  cardId: string;
  handleSelectedCardId?: Dispatch<SetStateAction<string | null>>;
  sampleFileName?: string | null;
  uploadProgressData?: {
    state: 'pending' | 'progress' | 'completed';
    description: string;
  };
  uploadPrompt?: React.ReactNode;
  acceptFileTypes?: AcceptFileTypes;
}

const MAX_FILE_SIZE = 26214400;

function validateFileSize(file: File) {
  if (file.size > MAX_FILE_SIZE) {
    return {
      code: 'file-too-big',
      message: 'File size too big!'
    };
  }

  return null;
};

function UploadCard({ title, helperText, uploadedFilesData, fileType, cardId, handleSelectedCardId, sampleFileName, uploadProgressData, uploadPrompt, acceptFileTypes }: UploadCardProps) {
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const fileIdToReplace = useRef<string | null>(null);
  const containerDiv = useRef<HTMLDivElement>(null);

  const { data: userDetails } = useUserDetails();
  const userRole = userDetails?.role;
  const isFileUploadAllowed = [
    USER_ROLES.ADMIN as string,
    USER_ROLES.DISTRICT_DATA_LEAD as string,
    USER_ROLES.FACILITATOR as string
  ].includes(userRole as string);

  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    const hasAnchorId = location.hash && location.hash.length > 0;
    const shouldScrollToCard = containerDiv.current && location.hash.includes(cardId);

    if (!hasAnchorId || !shouldScrollToCard) return;

    containerDiv.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const handleDrop = (file: File[]) => {
    // drop zone is limited to 1 file from configs
    setFile(file[0]);
  };

  const accept = acceptFileTypes ?? {
    'text/csv': ['.csv'],
    'application/vnd.ms-excel': ['.xls'],
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx']
  };

  const { getRootProps, getInputProps, isDragAccept, open } = useDropzone({
    multiple: false,
    maxFiles: 1,
    onDrop: handleDrop,
    accept,
    validator: validateFileSize
  });

  const handleClosePrompt = () => {
    setFile(null);
    fileIdToReplace.current = null;
  };

  const handleResubmit = (currentFileId: string) => {
    open();
    fileIdToReplace.current = currentFileId;
  };

  const getFileToReplaceMetadata = () => {
    let fileData = uploadedFilesData.find(fileData => fileData.file_id === fileIdToReplace.current);
    if (!fileData) return {};

    let previousMetadata = fileData.user_provided_file_metadata;
    let parsedMetadata: FileMetadata = {};

    for (const metadataKey in previousMetadata) {
      let metaData = previousMetadata[metadataKey];
      if (metaData) parsedMetadata[metadataKey] = metaData;
    }

    return parsedMetadata;
  };

  return (
    <>

      <Accordion id={cardId} ref={containerDiv} defaultExpanded>
        <AccordionSummary
          expandIcon={<img alt="expand accordion icon" src={ArrowDown} />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className="upload-card-header">
            <h2>{title}</h2>
            { uploadProgressData ? 
              <Tag type={uploadProgressData.state} text={uploadProgressData.description} size="medium" className="upload-progress-info" />
              :
              <p>Missing upload progress data!</p>
            }
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="upload-card-helper-text">
            {helperText.map((text, index) => <p key={index}>{text}</p>)}
          </div>
          <div className="upload-card-actions">
            {handleSelectedCardId && <CustomButton
              variant="text"
              buttonText="See what to upload"
              buttonAction={() => handleSelectedCardId(cardId)}
              icon={<img src={ArrowRight} alt="open modal / open sidebar"/>}
              iconPosition="end"
              sx={{ marginLeft: 'calc(var(--scaling-spacing-16) * -1)' }}
            />}
            {sampleFileName && <a data-testid="example file link" href={`/example files/${sampleFileName}`} download={sampleFileName}>
              <CustomButton
                variant="text"
                buttonText="View sample file"
                icon={<img src={DownloadIcon} alt="download"/>}
                iconPosition="end"
                buttonAction={() => { }}
              />
            </a>}
          </div>
          <div data-testid="upload-card-content" className={`upload-card-content ${isSmallScreen ? 'small-screen' : ''}`}>
            {isFileUploadAllowed &&
              <div data-testid="drop-zone" className="upload-card-drop-zone">
                <section>
                  <div {...getRootProps()} className={`drop-zone ${isDragAccept ? 'drag-active' : ''}`}>
                    <input data-testid="drop-zone-file-input" {...getInputProps()} />
                    <img src={UploadIcon} alt="upload file icon" className="upload-icon"></img>
                    <p className="drop-zone-text">Drag & drop to upload</p>
                    <span className="drop-zone-text">or</span>
                    <span className="drop-zone-text link-color"> browse files</span>
                    <div className="drop-zone-helper-text-container">
                      <p className="drop-zone-helper-text">Maximum file size is 25MB</p>
                      <p className="drop-zone-helper-text">Supported file types are {Object.values(accept).flat().join(', ')}</p>
                    </div>
                  </div>
                </section>
                {/* <div className="separator">
                  <span className="separator-text">or</span>
                </div>
                <button className="sign-in-button" disabled>Sign in via SIS</button> */}
              </div>
            }
            {uploadedFilesData && uploadedFilesData.length > 0 ?
              <UploadedFilesList 
                uploadedFilesData={uploadedFilesData} 
                fileType={fileType} 
                handleResubmit={handleResubmit} 
                uploadProgress={uploadProgress}
              />
              :
              null
            }
          </div>
        </AccordionDetails>
      </Accordion>

      {file ?
        <UploadCardContext.Provider value={{
          handleClose: handleClosePrompt,
          fileName: file.name,
          fileType: fileType,
          file: file,
          fileIdToReplace: fileIdToReplace.current,
          handleUploadProgress: setUploadProgress,
          fileMetadata: getFileToReplaceMetadata(),
          triggerFileSelect: open
        }} >
          {uploadPrompt ?? <UploadPrompt />}
        </UploadCardContext.Provider>
        :
        null
      }
      <CustomDivider orientation="horizontal" />
    </>
  );
}

export default UploadCard;