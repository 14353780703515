import ReactFlow from 'reactflow';
import CustomNode from './CustomNode';
import { type Edge, MarkerType } from 'reactflow';

import 'reactflow/dist/style.css';

const mockNodes = [
  {
    id: '1',
    type: 'customNode',
    data: { label: 'Math', value: 'M53153' },
    position: { x: 0, y: 0 },
  },
  {
    id: '2',
    type: 'customNode',
    data: { label: 'Physics', value: 'M3151' },
    position: { x: 0, y: 75 },
  },
  {
    id: '3',
    type: 'customNode',
    data: { label: 'CAD', value: 'C2101' },
    position: { x: 130, y: 40 },
  },
  {
    id: '4',
    type: 'customNode',
    data: { label: 'Studio Architecture', value: 'C21021' },
    position: { x: 240, y: 0 },
  },
  {
    id: '5',
    type: 'customNode',
    data: { label: 'Mechanical Engineering', value: 'ME7199' },
    position: { x: 240, y: 75 },
  },
];
      
const mockEdges: Edge[] = [
  { id: 'edge-1', source: '1', target: '3', markerEnd: { type: MarkerType.ArrowClosed, height: 20, width: 20 }},
  { id: 'edge-2', source: '2', target: '3', markerEnd: { type: MarkerType.ArrowClosed, height: 20, width: 20 }},
  { id: 'edge-3', source: '3', target: '4', markerEnd: { type: MarkerType.ArrowClosed, height: 20, width: 20 }},
  { id: 'edge-4', source: '3', target: '5', markerEnd: { type: MarkerType.ArrowClosed, height: 20, width: 20 }},
];

const nodeTypes = {
  customNode: CustomNode,
};

// interface FlowDiagramProps {
//   nodes: {
//     id: string, 
//     type: string, 
//     data: {
//       label: string
//     }, 
//     position: {
//       x: number, 
//       y: number
//     }
//   }[];
//   edges: {
//     id: string;
//     source: string;
//     target: string;
//     markerEnd?: string;
//   }[];
// }

function FlowDiagram() {

  return (
    <ReactFlow 
      nodes={mockNodes} 
      edges={mockEdges} 
      nodeTypes={nodeTypes}
      nodesDraggable={false}
      panOnDrag={false}
      zoomOnScroll={false}
      zoomOnPinch={false}
      fitView={true}
      attributionPosition="top-right"
      proOptions={{ hideAttribution: true }}
    />
  );
}

export default FlowDiagram;
