import { Handle, Position } from 'reactflow';
import 'reactflow/dist/style.css';

const CustomNode = ({ id, data }: any) => {

  const isLeftHandleOnly = id === '4' || id === '5';
  const isRightHandleOnly = id === '1' || id === '2';
  
  return (
    <div style={{
      padding: '8px',
      borderRadius: 'var(--border-radius-10)',
      border: '2px solid #000000',
      backgroundColor: 'var(--accent-green-light)'
    }}>
      <p className="bolded no-margin">{data.label}</p>
      <p className="no-margin">{data.value}</p>
      {!isLeftHandleOnly && (
        <Handle
          type="source"
          position={Position.Right}
          style={{ opacity: 0 }}
        />
      )}
      {!isRightHandleOnly &&
          <Handle
            type="target"
            position={Position.Left} 
            style={{ opacity: 0 }}
          />
      }
    </div>
  );
};
export default CustomNode;