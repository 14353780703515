import CloseIcon from '../assets/close-icon.svg';
import CustomButton from '../reusable/CustomButton';
import CustomDivider from '../reusable/CustomDivider';
import './SideDrawer.css';

interface SideDrawerProps {
  title: string;
  description?: string;
  handleClose: () => void;
  children: React.ReactNode;
  isOpen: boolean;
  classes?: string[];
  icon?: string;
  divider?: boolean;
}

function SideDrawer({title, description, handleClose, children, isOpen, classes, icon, divider = true}: SideDrawerProps) {

  const classNames = classes ? classes.join(' ') : '';

  return (
    <div data-testid="side-drawer-container" className={`side-drawer-container ${!isOpen ? 'closed' : ''} ${classNames}`}>
      <div className="side-drawer-header">
        <p>{title}</p>
        {icon && <img className="icon-size" src={icon} alt="drawer header icon" />}
        <CustomButton className="close-icon" buttonAction={handleClose} buttonText="" variant="clear">
          <img src={CloseIcon} alt="close modal" />
        </CustomButton>
      </div>
      { description && <p className="side-drawer-description">{description}</p> }
      { divider && <CustomDivider orientation="horizontal"/> }
      <div className="side-drawer-content">
        {children}
      </div>
    </div>
  );
}

export default SideDrawer;