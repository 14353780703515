import { SelectChangeEvent, TextField } from '@mui/material';
import SelectDropdown from '../../reusable/SelectDropdown';
import { ConfiguredEOASchool, NewEOASchool } from '../../../interfaces/EOAInterface';


export const mockSchoolTypes = [{ name: 'type1' }, { name: 'type2' }];

type School = NewEOASchool | ConfiguredEOASchool;
interface SchoolDataFieldsProps<T> {
    currentSchool: T;
    setCurrentSchool: React.Dispatch<React.SetStateAction<T>>;
}

function SchoolDataFields<T extends School>({currentSchool, setCurrentSchool}: SchoolDataFieldsProps<T>) {

  const handleInputChange = (event: { target: { name: string; value: string } }) => {
    const { name, value } = event.target;
    setCurrentSchool((prev: any) => ({ ...prev, [name]: value }));
  };
    
  const handleSelectSchoolType = (event: SelectChangeEvent) => {
    setCurrentSchool((prev: any) => ({ ...prev, school_type: event.target.value }));
  };
      
  return (
    <>
      <TextField
        required
        placeholder="Enter school name"
        variant="outlined"
        name="name"
        value={currentSchool.name}
        onChange={handleInputChange}
      />
      <TextField
        placeholder="Enter school identifier"
        variant="outlined"
        name="school_identifier"
        value={currentSchool.school_identifier}
        onChange={handleInputChange}
      />
      <SelectDropdown
        label="School Type"
        selectedValue={currentSchool.school_type}
        handleChange={handleSelectSchoolType}
        handleDelete={() => setCurrentSchool((prev: any) => ({ ...prev, school_type: '' }))}
        items={mockSchoolTypes.map((mockSchoolType) => ({
          label: mockSchoolType.name,
          value: mockSchoolType.name,
        }))}
        formControlVariant="outlined"
      />
    </>
  );
}

export default SchoolDataFields;