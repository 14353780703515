import { useState } from 'react';
import { RostersSummary, TranscriptsSummary } from '../../interfaces/DataQcInterface';
import CustomPagination from '../reusable/CustomPagination';

import './DataQcCardContentWithTable.css';

interface DataQcCardContentWithTableProps {
  schools: RostersSummary['schools'] | TranscriptsSummary['schools'];
  columns: {[key: string]: string}[];
  totalItems: number;
  pageSize: number;
}

function DataQcCardContentWithTable({schools, columns, totalItems, pageSize }: DataQcCardContentWithTableProps) {
  const [currentPage, setCurrentPage] = useState(1);

  const sliceStartIndex = (currentPage - 1) * pageSize;
  const sliceEndIndex = currentPage * pageSize;

  return (
    <div className="table-with-pagination">
      <table className="data-qc-content-table table">
        <thead className="data-qc-content-table-header">
          <tr className="data-qc-content-table-row">
            <th className="align-left">School</th>
            <th className="data-qc-content-th-students align-right"># of students</th>
          </tr>
        </thead>
        <tbody className="data-qc-content-table-body">
          {schools.slice(sliceStartIndex, sliceEndIndex).map((dataRow, index) => (
            <tr key={index} className="data-qc-content-table-row">
              {columns.map(({key, className}) => (
                <td className={className} key={key}>
                  {dataRow[key as keyof typeof dataRow]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      { totalItems > 10 &&
        <CustomPagination
          currentPage={currentPage}
          handlePageChange={setCurrentPage}
          pages={Math.ceil(totalItems / pageSize)}
          totalItems={totalItems}
          pageSize={pageSize}
        />
      }
    </div>
  );
}

export default DataQcCardContentWithTable;
