import { useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { getCourseCatalogsDetails } from '../../../services/dataQcDetailsService';
import SelectDropdown from '../../reusable/SelectDropdown';
import { useDataQcOutletContext } from '../../hooks/useDataQcOutletContext';
import { AcademicPeriod, CourseCatalogDetails, SubjectArea } from '../../../interfaces/DataQcInterface';
import { withData } from '../../reusable/withData';
import CustomDivider from '../../reusable/CustomDivider';
import DataQcCardInfoGrid from '../cte courses/DataQcCardInfoGrid';

import './CourseCatalogSideDrawerContent.css';

const gridItemsLables: { [key in keyof Omit<SubjectArea, 'name' | 'course_count'>]: string } = {
  ap_count: 'AP courses',
  ib_count: 'IB courses',
  // general_count: 'General courses',
  is_college_preparatory_count: 'College Preparatory courses',
  honors_count: 'Honors courses',
  cte_count: 'CTE courses',
};

interface CourseCatalogSideDrawerContentProps {
  data: CourseCatalogDetails
}

export function CourseCatalogSideDrawerContent({data}: CourseCatalogSideDrawerContentProps) {
  const {academic_periods} = data;

  const [selectedPeriod, setSelectedPeriod] = useState(academic_periods[academic_periods.length - 1].period);

  const filterData = () => {
    if (!selectedPeriod || selectedPeriod.length === 0) return academic_periods;

    return academic_periods.filter((academicPeriod) => academicPeriod.period === selectedPeriod);
  };

  const generateItems = (subjectArea: SubjectArea) => {
    let items = (Object.keys(gridItemsLables) as Array<keyof typeof gridItemsLables>).map((key) => ({
      label: gridItemsLables[key], value: subjectArea[key]
    }));

    return items;
  };

  const renderData = (filteredData: AcademicPeriod[]) => {
    return filteredData.map((academicPeriod, index) => (
      <div key={index} className="course-catalogs-details" data-testid="course-catalogs-details">
        <div className="header">
          <p><span>{academicPeriod.course_count}</span>courses</p>
          <p><span>{academicPeriod.subject_area_count}</span>subject areas</p>
        </div> 
        {academicPeriod.subject_areas.map((subjectArea, index) => (
          <div key={index} data-testid="subject-area-list">
            <CustomDivider orientation="horizontal" className="light"/>
            <p className="subject">{subjectArea.name}: {subjectArea.course_count} courses</p>
            <div className="courses">
              <DataQcCardInfoGrid items={generateItems(subjectArea)} />
            </div>
          </div>
        ))}
      </div>
    ));
  };

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedPeriod(event.target.value);
  };

  return (
    <div>
      <SelectDropdown
        label="Year"
        selectedValue={selectedPeriod}
        handleChange={handleChange}
        handleDelete={() => setSelectedPeriod('')}
        items={data.academic_periods.map((academicPeriod) => {
          return { label: academicPeriod.period, value: academicPeriod.period };
        })}
        formControlClasses={['width-40']}
        fullWidth={false}
      />
      { renderData(filterData()) }
    </div>
  );
}

const CourseCatalogSideDrawerContentWithData = () => {
  
  const { selectedSchoolFromDataQc } = useDataQcOutletContext();

  const WrappedComponent = withData({
    WrappedComponent: CourseCatalogSideDrawerContent,
    fetchData: (eoaId: string, access_token: string) => getCourseCatalogsDetails(eoaId, access_token, selectedSchoolFromDataQc),
    errorMessage: 'Error occurred while fetching course catalog details data!',
    options: {
      dependencies: [selectedSchoolFromDataQc]
    }
  });

  return <WrappedComponent />;
};

export default CourseCatalogSideDrawerContentWithData;